// Core
import { ref, onUnmounted } from "vue";

export const useTimeout = () => {
  const timeoutIds = ref<NodeJS.Timeout[]>([]);
  const intervalIds = ref<NodeJS.Timeout[]>([]);

  const clearTimeoutIds = () => {
    timeoutIds.value.forEach(id => clearTimeout(id));
    timeoutIds.value = [];
  };

  const clearIntervalIds = () => {
    intervalIds.value.forEach(id => clearInterval(id));
    intervalIds.value = [];
  };

  const clearAllTimers = () => {
    clearTimeoutIds();
    clearIntervalIds();
  };

  onUnmounted(() => {
    clearAllTimers();
  });

  return {
    timeoutIds,
    intervalIds,
    clearTimeoutIds,
    clearIntervalIds,
    clearAllTimers
  };
};
