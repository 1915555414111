// Services & Helpers
import { defaultErrorMessages } from "./defaultErrors";
import { handleRuntimeErrors } from "@/helpers/error-handler";

// Types
import { ApiErrorCode } from "@/@types/api";
import { ErrorTable, ToastErrorMessage } from "./types";

export class ErrorHandlerService {
  private errorTable: Partial<ErrorTable>;
  private localCustomErrorTable: Partial<ErrorTable> | undefined;

  constructor(errorTable = defaultErrorMessages, localCustomErrorTable?: Partial<ErrorTable>) {
    this.errorTable = errorTable;
    this.localCustomErrorTable = localCustomErrorTable;
  }

  handleError(
    error?: ApiErrorCode | unknown,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast?: boolean
  ): Partial<ToastErrorMessage>;

  handleError(
    error?: string,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast?: boolean
  ): Partial<ToastErrorMessage>;

  handleError(
    error?: ApiErrorCode,
    customMessage?: Partial<ErrorTable>,
    param?: Record<string, string>,
    showToast = true
  ) {
    const errorMessage = this.getDefaultErrorMessage(error || "default", customMessage);
    const errorMessageWithParams = errorMessage?.message?.replaceAll(
      /\{\{(\w+)\}\}/g,
      (match, key) => param?.[key] || match
    );
    errorMessage.message = errorMessageWithParams || errorMessage.message;
    handleRuntimeErrors(error, { ...errorMessage, showToast: showToast });
    return errorMessage;
  }

  getDefaultErrorMessage(code: ApiErrorCode | string, custom: Partial<ErrorTable> = {}): Partial<ToastErrorMessage> {
    // Use 'this.localCustomErrorTable' se 'custom' não for passado
    const effectiveCustom = Object.keys(custom).length > 0 ? custom : this.localCustomErrorTable;
  
    const defaultErrorMessage = effectiveCustom?.default || (code in this.errorTable ? this.errorTable[code as keyof ErrorTable] : this.errorTable.default!);
    const customErrorMessage = effectiveCustom && code in effectiveCustom ? effectiveCustom[code as keyof ErrorTable] : undefined;
  
    if (!effectiveCustom || !customErrorMessage) {
      return defaultErrorMessage as ToastErrorMessage;
    }
  
    if (customErrorMessage.message && customErrorMessage.title) return customErrorMessage;
    if (!customErrorMessage?.message) customErrorMessage.message = defaultErrorMessage?.message;
    if (!customErrorMessage?.title) customErrorMessage.title = defaultErrorMessage?.title;
  
    return customErrorMessage;
  }
  
}

export const errorHandlerService = new ErrorHandlerService(defaultErrorMessages);
