// Core
import { computed } from "vue";

// Libraries
import { defineStore } from "pinia";

// Services & Helpers
import { millisToMinutes } from "@/utils/time";
import { apiService } from "@/core/services/apiService";
import { handleRuntimeErrors } from "@/helpers/error-handler";

// Composables
import { useUser } from "@/composables/useUser";
import { toast } from "@/composables/useCustomToast";

// Stores
import { useUserStore } from "@/store/user";
import { useProjectStore } from "@/store/project";

// Types
import type {
  Team,
  Tags,
  User,
  Invite,
  Billings,
  UserProjects,
  PlanFeaturesT,
  TeamStatisticsT,
  TeamStoreStates
} from "@/@types/team";
import type { TagColorValue } from "@/@types/tag-input";
import type { SubscriptionT } from "@/@types/subscription";

const { userStatus, updateStatus } = useUser();

const getTeamTotalUsage = (users: Record<string, User>) => {
  const total = Object.values(users).reduce((acc, user) => {
    return acc + (user?.usage?.totalWordsCount || 0);
  }, 0);
  return total;
};

export const useTeamStore = defineStore({
  id: "Team",
  state: (): TeamStoreStates => ({
    teams: [],
    invites: [],
    billings: [],
    paymentLink: null,
    currentTeamUsage: 0,
    loading: false,
    tags: null,
    isSwitchingTeam: false,
    subscriptions: [],
    limits: {
      users: {
        limit: "unlimited"
      },
      projects: {
        limit: "unlimited"
      },
      folders: {
        hasAccess: true
      },
      credits: {
        limit: "unlimited"
      },
      "llm-models": {
        hasAccess: true
      },
      transcription: {
        hasAccess: true
      },
      docbase: {
        hasAccess: true
      },
      "blog-writer": {
        hasAccess: true
      },
      meetbase: {
        limit: "unlimited"
      },
      "lead-machine-leads": {
        limit: "unlimited"
      },
      "lead-machine-lead-words": {
        limit: "unlimited"
      },
      "lead-machine-weekly-leads": {
        limit: "unlimited"
      },
      "custom-models": {
        hasAccess: true
      },
      "image-generator": {
        limit: "unlimited"
      },
      integrations: {
        hasAccess: true
      },
      workflows: {
        hasAccess: true
      },
      automations: {
        hasAccess: true
      },
      plagiarism: {
        limit: "unlimited"
      },
      "claude-3.5": {
        hasAccess: true
      },
      "gemini-1.5-pro": {
        hasAccess: true
      },
      "llama-3.1": {
        hasAccess: true
      }
    },
    statistics: null,
    generalStatistics: null
  }),
  actions: {
    setPaymentLink(link: string | null) {
      this.paymentLink = link;
    },
    async fetchTeams() {
      this.loading = true;

      const { error, data } = await apiService.get<Team[]>({ endpoint: "teams" });

      if (!data || error) {
        return error;
      }

      this.teams = data;

      await Promise.all([this.fetchInvites(), this.fetchTeamLimits(), this.fetchSubscriptions()]);

      this.loading = false;

      return error;
    },
    async fetchUsersProjects() {
      const teamId = userStatus.value?.activeTeam?.id;
      if (!teamId) return;
      const endpoint = `teams/${teamId}/users/projects`;
      const result = await apiService.get<UserProjects>({ endpoint });
      return result;
    },
    async fetchBillings(teamId: string) {
      const res = await apiService.get<Billings>({ endpoint: `payments?teamId=${teamId}` });

      if (res.error || !res.data) {
        return res;
      }

      this.billings = res.data.payments;
      return res;
    },
    async acceptInvite(id: string, userId: string, email: string, teamId: string) {
      const body = {
        userId: userId,
        email: email,
        teamId: teamId,
        inviteId: id
      };

      const result = await apiService.post({
        endpoint: "teams/accept-invite",
        body
      });
      await Promise.all([this.fetchTeams(), updateStatus()]);

      return result;
    },
    async fetchInvites() {
      const { error, data } = await apiService.get<Invite[]>({ endpoint: "teams/pending-invites" });

      if (error || !data) {
        return;
      }

      this.invites = data;
      localStorage.setItem("invitesCount", data?.length.toString() || "0");
    },
    async updateTeam(team: Partial<Omit<Team, "id" | "users" | "createdAt" | "updatedAt">> & Pick<Team, "id">) {
      const result = await apiService.patch({
        endpoint: `teams/${team.id}`,
        body: {
          ...team,
          id: undefined
        }
      });
      if (!result.error) this.fetchTeams();
      return result;
    },
    async switchTeam(team: Pick<Team, "id"> & Partial<Omit<Team, "id">>, showToast = true) {
      const userStore = useUserStore();
      const projectStore = useProjectStore();

      /* NOTE: 
        A variável isSwitchingTeam foi criada para reagirmos à mudança de time do usuário.
        Um watcher no Vue 3 pode ser executado em momentos inesperados devido a como 
        o Vue detecta e reage às mudanças de estado. Quando chamamos updateStatus() ao fim dessa função, 
        o Vue detecta a mudança em userStatus.value e imediatamente dispara o watcher 
        associado a essa propriedade, mesmo que a função switchTeam ainda não tenha 
        terminado a sua execução. Isso ocorre porque a reatividade do Vue é síncrona.
        Por isso, a partir de agora, se queremos reagir a mudanças de estado do time usuário,
        vamos usar essa variável para garantir que o watcher só seja disparado após o término completo 
        da função switchTeam.
      */
      this.isSwitchingTeam = true;

      projectStore.projects = [];
      projectStore.listingPage = 1;

      const result = await apiService.patch({ endpoint: `users/switch-team/${team.id}` });

      this.getTeamTags();

      if (result.error) {
        this.isSwitchingTeam = false;

        handleRuntimeErrors(result.error, {
          title: "Algo deu errado.",
          message: "Você continua na mesma equipe. Vamos tentar de novo?"
        });

        return;
      } else {
        if (showToast)
          toast.success({
            title: "Uhuul!",
            message: `Agora você está na equipe ${team.name}!`,
            duration: 3000
          });
      }

      await Promise.all([
        updateStatus(),
        this.fetchTeamLimits(),
        this.fetchSubscriptions(),
        projectStore.fetchProjects()
      ]);

      userStore.userInfos.activeTeam = {
        id: userStatus.value?.activeTeam?.id || "",
        name: userStatus.value?.activeTeam?.name || "",
        role: userStatus.value?.activeTeam?.role || ""
      };

      this.isSwitchingTeam = false;
    },
    addWordsToCurrentTeam(words: number) {
      const id = userStatus.value?.activeTeam?.id;
      if (!id) return;
      for (let i = 0; i < this.teams.length; i++) {
        const team = this.teams[i];

        if (!team) continue;

        if (team.id === id) {
          const user = team.users[userStatus.value?.email];

          if (user?.usage) {
            user.usage.totalWordsCount += words;
            return;
          }
        }
      }
    },
    async fetchUser(user: string) {
      const res = await apiService.get({ endpoint: `users/${user}` });
      return res.data;
    },
    async getTeamTags(teamId?: string) {
      teamId = teamId || userStatus.value?.activeTeam.id;
      const res = await apiService.get<Tags[]>({ endpoint: `teams/${teamId}/tags` });

      if (res.error || !res.data) {
        this.tags = null;
      } else {
        this.tags = res.data;
      }
    },
    async updateTeamTags(tagId: string, data: { color?: TagColorValue; name?: string }) {
      const tagIndex = this.tags?.findIndex((tag: { id: string }) => tag.id === tagId);
      if (tagIndex && tagIndex >= 0 && !!this.tags && !!this.tags[tagIndex]) {
        this.tags[tagIndex].color = data.color || this.tags?.[tagIndex].color;
        this.tags[tagIndex].name = data.name || this.tags?.[tagIndex].name;
      }
    },
    async migrateTeamProjects() {
      const currTeam: Team = this.currentTeam!;

      if (currTeam && !currTeam.lastMigratedAt) {
        await apiService.post({ endpoint: `teams/${currTeam.id}/train` });
      }
    },
    async fetchTeamLimits() {
      const teamId = this.currentTeam?.id || userStatus.value?.activeTeam?.id;

      if (!teamId) return;

      const res = await apiService.get<PlanFeaturesT>({ endpoint: `teams/${teamId}/limits` });

      if (res.data) {
        this.limits = res.data;
      }
    },
    async fetchSubscriptions() {
      const { data } = await apiService.get<{ subscriptions: SubscriptionT[]; count: number }>({
        endpoint: "subscriptions"
      });

      if (data) this.subscriptions = data.subscriptions;
    },
    async fetchTeamStatistics(id?: string | null, filtered?: boolean, startDate?: string, endDate?: string) {
      const teamId = id || userStatus.value?.activeTeam?.id;

      if (!filtered) {
        this.generalStatistics = null;
      } else {
        this.statistics = null;
      }

      try {
        const { data, errorData, error } = await apiService.get<TeamStatisticsT>({
          endpoint: `teams/${teamId}/usages${startDate ? `?startDate=${startDate}` : ""}${endDate ? `&endDate=${endDate}` : ""}`
        });

        if (!data || errorData?.message || error) {
          throw new Error(errorData?.message || "Error fetching team statistics");
        }

        if (!filtered) {
          this.generalStatistics = data;
        } else {
          this.statistics = { ...data, originalUsers: data.users };
        }
      } catch (error) {
        handleRuntimeErrors(error);
      }
    }
  },
  getters: {
    tagsMap(state: TeamStoreStates) {
      const tags: Record<string, Tags> = {};
      state.tags?.forEach(tag => {
        tags[tag.id] = tag;
      });
      return tags;
    },
    currentTeam(state: TeamStoreStates) {
      const id = userStatus.value?.activeTeam?.id;
      if (!id) return null;
      return state.teams.find(team => team.id === id) || null;
    },
    currentUser(state: TeamStoreStates) {
      const id = userStatus.value?.activeTeam?.id;
      if (!id) return null;
      return state.teams.find(team => team.id === id)?.users[userStatus.value?.email] || null;
    },
    currentTeamLimit(state: TeamStoreStates) {
      const id = userStatus.value?.activeTeam?.id;
      if (!id) return null;
      return state.limits.credits.limit || state.teams.find(team => team.id === id)?.limit || 0;
    },
    currentTeamSubscriptions(): SubscriptionT[] {
      return this.subscriptions;
    },
    teamHasLeadMachine(state: TeamStoreStates): boolean {
      if (userStatus.value.email?.includes("@copybase")) return true;

      if (!this.currentTeam) return false;

      return !!(
        state.limits["lead-machine-lead-words"].limit === "unlimited" ||
        state.limits["lead-machine-lead-words"].limit > 0
      );
    },
    teamHasMeetbase(state: TeamStoreStates): boolean {
      const userStore = useUserStore();

      const user = userStore.userInfos;

      if (user.email?.includes("@copybase")) return true;

      if (!this.currentTeam) return false;

      return !!(state.limits["meetbase"].limit === "unlimited" || state.limits["meetbase"].limit > 0);
    },
    teamIsLeadMachineOnly(): boolean {
      if (!this.currentTeam) return false;

      return !!(!this.userHasValidStatus && this.teamHasLeadMachine);
    },
    userGenStatus(state: TeamStoreStates) {
      const defaults = {
        totalWordsCount: 0,
        totalContentCount: 0,
        totalWordsCountPast: {},
        totalImagesCountPast: {},
        totalImagesGeneratedOverall: 0,
        totalDocumentsCount: 0,
        totalDocumentsCountPast: {},
        totalDocumentsGeneratedOverall: 0,
        totalWordsGeneratedOverall: 0,
        totalImagesCount: 0,
        totalPlagiarismWordsCount: 0,
        totalChatsCount: 0,
        totalChatsGeneratedOverall: 0,
        totalLeadContentsCount: 0,
        totalLeadWordsCount: 0,
        meetBaseMinutesUsed: 0,
        meetBaseMinutesUsedPast: {},
        meetBaseMinutesUsedOverall: 0,
        meetBaseTranscriptionsCount: 0,
        meetBaseTranscriptionsCountPast: 0,
        meetBaseTranscriptionsCountOverall: 0,
        meetBaseTranscriptionsWordsCount: 0,
        meetBaseTranscriptionsWordsCountPast: {},
        meetBaseTranscriptionsWordsCountOverall: 0
      };

      const id = userStatus.value?.activeTeam?.id;

      if (!id) return defaults;

      const currentTeam = state.teams.find(team => team.id === id);
      const currentUser = currentTeam?.users[userStatus.value?.email];

      if (!currentUser || !currentTeam) return defaults;

      return {
        totalImagesCountPast: currentTeam.usage.totalImagesCountPast,
        totalImagesGeneratedOverall: currentTeam.usage.totalImagesGeneratedOverall,
        totalDocumentsCount: currentTeam.usage.totalDocumentsCount,
        totalDocumentsCountPast: currentTeam.usage.totalDocumentsCountPast,
        totalDocumentsGeneratedOverall: currentTeam.usage.totalDocumentsGeneratedOverall,
        totalPlagiarismWordsCount: currentTeam.usage.totalPlagiarismWordsCount,
        totalChatsCount: currentTeam.usage.totalChatsCount,
        totalChatsGeneratedOverall: currentTeam.usage.totalChatsGeneratedOverall,
        totalLeadContentsCount: currentTeam.usage.totalLeadContentsCount,
        totalLeadWordsCount: currentTeam.usage.totalLeadWordsCount,
        meetBaseMinutesUsed: currentTeam.usage.meetBaseMinutesUsed,
        meetBaseMinutesUsedPast: currentTeam.usage.meetBaseMinutesUsedPast,
        meetBaseMinutesUsedOverall: currentTeam.usage.meetBaseMinutesUsedOverall,
        meetBaseTranscriptionsCount: currentTeam.usage.meetBaseTranscriptionsCount,
        meetBaseTranscriptionsCountPast: currentTeam.usage.meetBaseTranscriptionsCountPast,
        meetBaseTranscriptionsCountOverall: currentTeam.usage.meetBaseTranscriptionsCountOverall,
        meetBaseTranscriptionsWordsCount: currentTeam.usage.meetBaseTranscriptionsWordsCount,
        meetBaseTranscriptionsWordsCountPast: currentTeam.usage.meetBaseTranscriptionsWordsCountPast,
        meetBaseTranscriptionsWordsCountOverall: currentTeam.usage.meetBaseTranscriptionsWordsCountOverall,
        totalContentCount: currentUser.usage?.totalContentCount,
        totalImagesCount: currentUser.usage?.totalImagesCount,
        totalWordsCount: currentUser.usage?.totalWordsCount,
        totalWordsCountPast: currentUser.usage?.totalWordsCountPast,
        totalWordsGeneratedOverall: currentUser.usage?.totalWordsGeneratedOverall
      };
    },
    userWordLimit(state: TeamStoreStates) {
      // No caso de time ou usuários serem admin(internos da copybase) as palavras serão iguais ao business5
      const id = userStatus.value?.activeTeam?.id;
      const currentTeam = state.teams?.find(team => team.id === id);
      const currentUser = currentTeam?.users[userStatus.value?.email];

      if (userStatus.value?.role === "admin") return 1000000;

      // Caso não haja um id de time ativo o user não terá creditos. Isso pode se dar por algum problema na migração
      if (!id) return 0;

      // Caso o usuário seja admin de seu time, o seu limite de palavras será o limite do time
      if (userStatus.value?.activeTeam?.role === "admin") {
        return state.limits.credits.limit !== "unlimited" ? state.limits.credits.limit : 1000000;
      }

      if (!currentUser) return 0;

      // Essa condição precisa ser verificada, caso o user não tenha limite dentro de um time, mas não é admin
      // qual valor se debe atribuir ao mesmo?
      if (!currentUser.limit) {
        const total = currentTeam.usage.totalWordsCount || getTeamTotalUsage(currentTeam.users);
        const limit = state.limits.credits.limit !== "unlimited" ? state.limits.credits.limit : 1000000;
        return limit - total < 0 ? 0 : limit - total;
      }

      // Caso o user tenha um time atual em que não é admin e possui limite atribuido a ele, seu limite será o limite
      // estipulado pelo time a ele.
      return currentUser.limit || 0;
    },
    teamHasCredit(state: TeamStoreStates): boolean {
      // No caso de time ou usuários serem admin(internos da copybase) as palavras serão iguais ao business5
      if (userStatus.value?.role === "admin") return true;
      if (state.limits.credits.limit === "unlimited") return true;

      const id = userStatus.value?.activeTeam?.id;

      if (!id) return false;

      const currentTeam = state.teams.find(team => team.id === id);

      if (!currentTeam) return false;

      // Caso o time não possua um limite em sua entidade, o limite do time será comparado com o limite do time buscado na tabela localmente
      return (state.limits.credits.limit || 0) > currentTeam.usage.totalWordsCount;
    },
    userHasValidStatus(state: TeamStoreStates): boolean {
      if (userStatus.value?.role === "admin") return true;

      const currentTeamId = userStatus.value?.activeTeam?.id;

      if (!currentTeamId) return false;

      const currentTeam = state.teams.find(team => team.id === currentTeamId);
      const currentUser = state.teams.find(team => team.id === currentTeam?.id)?.users[userStatus.value?.email];

      if (!currentUser) return false;

      return !(
        currentTeam?.status === "trial-expired" ||
        currentTeam?.status === "lead" ||
        currentTeam?.status === "overdue" ||
        currentTeam?.status === "inactive" ||
        currentUser?.status === "pending" ||
        currentUser?.status === "inactive" ||
        currentUser?.role === "failed" ||
        currentUser?.role === "canceled" ||
        currentUser?.role === "trial-expired"
      );
    },
    pendingInvites(state: TeamStoreStates) {
      const pending = state.invites.filter(invite => !state.teams.find(team => team.id === invite.teamId));

      return pending;
    },
    isSubscriptionLate(state: TeamStoreStates): boolean {
      const subscriptions = state.subscriptions;

      return subscriptions.length > 0 && subscriptions.every(subscription => subscription.status === "overdue");
    },
    hasValidSubscription(state: TeamStoreStates): boolean {
      return (
        state.subscriptions.length > 0 && !!state.subscriptions.find(subscription => subscription.status === "active")
      );
    },
    remainingTrialDays() {
      const userStore = useUserStore();
      const isTrialOrNoCard = ["trial", "no-card"].includes(userStore.$state.userInfos.role);

      let remainingMinutes = 0;
      let remainingDays = 0;

      if (isTrialOrNoCard) {
        const createdAt = new Date((userStore.userInfos.createdAt as unknown as { _seconds: number })._seconds * 1000);
        const dataNow = new Date(Date.now());

        const remaining = createdAt.getTime() - dataNow.getTime();
        remainingMinutes = millisToMinutes(remaining);

        remainingDays = Math.ceil(remainingMinutes / 1440);

        return remainingDays;
      }
      return;
    },
    teamHasMeetbaseMinutes(): boolean {
      if (userStatus.value.email.includes("@copybase") || userStatus.value.email.includes("@baseworks")) return true;

      if (!this.currentTeam) return false;

      const meetbaseLimit = this.limits.meetbase.limit;

      if (!meetbaseLimit) return false;

      return meetbaseLimit === "unlimited" ? true : this.currentTeam.usage.meetBaseMinutesUsed <= meetbaseLimit;
    },
    teamTier(): number {
      const team: Team | null = this.currentTeam;

      if (!team) return 1;

      const teamSector = team.sector.toLowerCase();
      const userPosition = userStatus.value.onboarding.position?.toLowerCase();

      if (!userPosition) return 1;

      const tier1 = (() => {
        const sector = teamSector === "agência / assessoria" || teamSector === "e-commerce";

        const role = [
          "ceo / fundador / presidente",
          "diretor / gerente / coordenador / c-level",
          "social media"
        ].includes(userPosition || "");

        const invoice = ["entre 5 e 10 mil", "entre 25 e 100 mil", "entre 25 e 100 mil", "mais de 100 mil"].includes(
          team.monthlyBilling ? team.monthlyBilling.toLowerCase() : ""
        );

        return sector && role && invoice;
      })();

      if (tier1) return 1;

      const tier2 = (() => {
        const sector = teamSector === "dropshipping";

        const role = ["estagiário", "presto serviço, não sou do quadro de sócios", "outros"].includes(
          userPosition || ""
        );

        return sector || role;
      })();

      if (tier2) return 2;

      return 3;
    },
    isPlanInvalid() {
      const team: Team | null = this.currentTeam;

      return team?.status === "lead" || team?.status === "inactive";
    }
  }
});

export const targetDate = computed(() => {
  const currentDate = new Date();
  const endDate = new Date("2023-11-30T00:00:00");

  const dayAfterTargetDate = new Date(endDate);
  dayAfterTargetDate.setDate(dayAfterTargetDate.getDate() + 1);

  if (currentDate < endDate) {
    return "isDayBefore";
  } else if (currentDate < dayAfterTargetDate) {
    return "isDay";
  } else {
    return "isDayAfter";
  }
});
