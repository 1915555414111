// Core
import { NavigationGuard } from "vue-router";

// Libraries
import { pinia } from "@/main";

// Stores
import { useTeamStore } from "@/store/team";
import { useUserStore } from "@/store/user";

const requireTeamAdmin: NavigationGuard = (_to, _from, next) => {
  try {
    const teamStore = useTeamStore(pinia);
    const userStore = useUserStore(pinia);

    const isTeamAdmin = userStore.userInfos.activeTeam?.role === "admin";

    if (teamStore.isPlanInvalid || teamStore.isSubscriptionLate || isTeamAdmin) {
      next();
    } else {
      next("/dashboard");
    }
  } catch (error) {
    next({ name: "500" });
  }
};

export default requireTeamAdmin;
