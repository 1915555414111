// Libraries
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

// Types
import { ApiErrorCode, ApiError } from "@/@types/api";

// Stores
import { useUserStore } from "@/store/user";

// // Services & Helpers
import { projectAuth } from "@/firebase/config";

export type ErrorStatus = ApiErrorCode | undefined;

interface Request {
  endpoint: string;
  body?: Record<string, unknown>;
}

interface Call extends Request {
  method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
}

class ApiService {
  private instance: AxiosInstance;
  private token: string | null = null;

  constructor(client: AxiosInstance) {
    this.instance = client;
    this.intercept();
  }

  async get<T>({ endpoint }: Omit<Request, "body">) {
    return this.send<T>({ method: "GET", endpoint });
  }

  async post<T>(data: Request) {
    return this.send<T>({ method: "POST", ...data });
  }

  async put<T>(data: Request) {
    return this.send<T>({ method: "PUT", ...data });
  }

  async patch<T>(data: Request) {
    return this.send<T>({ method: "PATCH", ...data });
  }

  async delete<T>(data: Request) {
    return this.send<T>({ method: "DELETE", ...data });
  }

  private async send<T>(
    call: Call,
    retry: boolean = true
  ): Promise<{ data: T | null; error: ErrorStatus | null; errorData: ApiError | null }> {
    try {
      const data = await this.fetch<T>(call);

      return { data, error: null, errorData: null };
    } catch (error) {
      const auth = error instanceof AxiosError && error.response?.status === 401;

      if (auth && retry) {
        await this.refresh();
        return this.send<T>(call, false);
      }

      if (error instanceof AxiosError) return this.treat(error);

      return { data: null, error: "unexpected-error", errorData: null };
    }
  }

  private async fetch<T>({ method, endpoint, body }: Call) {
    const response: AxiosResponse<T> = await this.instance({ method, url: endpoint, data: body });
    return response.data;
  }

  private async treat(error: AxiosError<ApiError>) {
    return { data: null, error: this.handleError(error), errorData: error.response?.data ?? null };
  }

  async refresh() {
    const token = await projectAuth.currentUser?.getIdToken();

    if (!token) return await this.redirect();

    this.token = token;
  }

  private async redirect() {
    const userStore = useUserStore();

    await userStore.logout();
    window.location.href = "/login";
  }

  intercept(token?: string) {
    this.token = token ?? this.token;

    this.instance.interceptors.request.use(async config => {
      config.headers.Authorization = `Bearer ${this.token}`;
      return config;
    });
  }

  private handleError(error: AxiosError<ApiError>): ApiErrorCode {
    if (error.response && error.response.data.error) {
      return error.response.data.error as ApiErrorCode;
    } else if (error.request) {
      return "network-error";
    } else {
      return "unexpected-error";
    }
  }
}

const baseURL = import.meta.env.VITE_NEW_API_BASE_URL;

const client = axios.create({ baseURL, headers: { "Content-Type": "application/json" } });
const apiService = new ApiService(client);

const multipart = axios.create({ baseURL, headers: { "Content-Type": "multipart/form-data" } });
const apiMultipartService = new ApiService(multipart);

export { apiService, apiMultipartService };
