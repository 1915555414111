// Core
import { ref, computed } from "vue";

// Libaries
// import Hotjar from "@hotjar/browser";
import { projectAuth } from "@/firebase/config";
import { updateProfile } from "firebase/auth";

// Services & Helpers
import { apiService } from "@/core/services/apiService";
import { errorHandlerService } from "@/core/services/error";

// Stores
import { useUserStore } from "@/store/user";

// Types
import { UserDetailsAdmin } from "@/@types/user";
import { User } from "firebase/auth";

type UserPreferences = {
  viewedLatestNews: boolean;
  dailyFirstAccessModal: boolean;
  disableUpdatedProjectFeatureModal?: boolean;
  theme: "light" | "dark";
};

const user = ref<User | null>(projectAuth.currentUser);
const userStatus = ref<UserDetailsAdmin>({} as UserDetailsAdmin);

export const isUserTeamMember = computed(() => {
  const role = userStatus.value?.activeTeam?.role;
  return role !== "admin";
});

const updateStatus = async () => {
  const userStore = useUserStore();

  if (!userStore?.userId) {
    userStatus.value = {} as UserDetailsAdmin;
    userStore.userInfos = {} as unknown as UserDetailsAdmin;
    return;
  }

  const result = await apiService.get<UserDetailsAdmin>({ endpoint: `users/${userStore?.userId}` });

  if (result.error || !result.data) {
    errorHandlerService.handleError(result.error);
    userStatus.value = {} as UserDetailsAdmin;
    userStore.userInfos = {} as unknown as UserDetailsAdmin;
    return;
  }

  userStatus.value = result.data;
  userStore.userInfos = result.data;

  // Hotjar.identify(userStore?.userId, {
  //   email: userStore?.userInfos?.email
  // });
};

const updateUserPreferences = async (preferences: Partial<UserPreferences>) => {
  if (userStatus.value) {
    userStatus.value.preferences = { ...userStatus.value.preferences, ...preferences };
  }

  const { error } = await apiService.patch({
    endpoint: `users`,
    body: { preferences: { ...preferences } }
  });

  return error;
};

const viewLatestNotification = async () => {
  return updateUserPreferences({
    viewedLatestNews: true
  });
};

const disableUpdatedProjectFeatureModal = async () => {
  return updateUserPreferences({
    disableUpdatedProjectFeatureModal: true
  });
};

const toggleDailyFirstAccessModal = async (value: boolean) => {
  return updateUserPreferences({
    dailyFirstAccessModal: value
  });
};

const setUserStatus = async (status?: UserDetailsAdmin) => {
  const userStore = useUserStore();

  userStatus.value = status ?? ({} as UserDetailsAdmin);
  userStore.userInfos = status ?? ({} as UserDetailsAdmin);

  user.value = projectAuth.currentUser;
};

const isUserFirstAccess = computed(() => {
  const userOnboardingStatus = userStatus.value?.onboarding;
  return !userOnboardingStatus?.hasCompletedTour && !userOnboardingStatus?.hasCompletedStep2;
});

const updateUserPhoto = async (photoURL: string) => {
  if (!user.value) return;
  
  await updateProfile(user.value, { photoURL });
  
  user.value = projectAuth.currentUser;
};

export const useUser = () => {
  return {
    user,
    userStatus,
    setUserStatus,
    isUserFirstAccess,
    updateStatus,
    updateUserPreferences,
    viewLatestNotification,
    toggleDailyFirstAccessModal,
    disableUpdatedProjectFeatureModal,
    updateUserPhoto
  };
};
