// Core
import { createApp, App as VueApp } from "vue";
import router from "./router";
import App from "./App.vue";

// Libraries
import "bootstrap";
// @ts-ignore
import drag from "v-drag";
import { createPinia } from "pinia";
import VueRewards from "vue-rewards";
import ElementPlus from "element-plus";
import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import { createHead } from "@vueuse/head";
import VueDragscroll from "vue-dragscroll";
import { MotionPlugin } from "@vueuse/motion";
import VueDatePicker from "@vuepic/vue-datepicker";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { plugin as VueInputAutowidth } from "vue-input-autowidth";
import { onAuthStateChanged, getAuth, signOut } from "firebase/auth";
import { handleRuntimeErrors } from "./helpers/error-handler";
import { apiMultipartService, apiService } from "./core/services/apiService";

// Services & Helpers
import { initToast } from "@/plugins/toast";
import { vFocus } from "./directives/focus";
import { projectAuth } from "./firebase/config";
import { initInlineSvg } from "@/plugins/inline-svg";
import { PtBr } from "./helpers/element-plus/lang-ptbr";
import { initializeAnalytics } from "./core/services/analyticsService";
import { storageSession } from "@/utils/storage";

// Composables
import { useUser } from "./composables/useUser";
import { useProjectStore } from "./store/project";

// Stores
import { useTeamStore } from "@/store/team";

// Assets
import "nprogress/nprogress.css";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/sass/components/_toast.scss";
import { UserDetailsAdmin } from "./@types/user";

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[];
    fbq: (type: string, event: string) => void;
    clarity: ((set: string, key: string, value: string) => void) & ((event: string, value: string) => void);
  }
}

let app: VueApp | undefined;
let head;

export const pinia = createPinia();

const initializeApp = () => {
  app = createApp(App);
  head = createHead();

  app.config.errorHandler = (error, vm, info) => {
    console.log(error, vm, info);
  };

  app.use(pinia.use(piniaPluginPersistedstate));
  app.use(head);
  app.use(router);
  app.use(drag, {});
  app.use(VueRewards);
  app.use(PrimeVue, {
    theme: {
      preset: Aura,
      options: {
        darkModeSelector: ".copybase-dark"
      }
    }
  });
  app.use(MotionPlugin);
  app.use(VueDragscroll);
  app.use(VueInputAutowidth);
  app.use(autoAnimatePlugin);
  app.use(ElementPlus, { locale: PtBr });

  app.component("VueDatePicker", VueDatePicker);

  app.directive("focus", vFocus);

  initInlineSvg(app);
  initToast(app);

  initializeAnalytics();

  app.mount("#app");
};

onAuthStateChanged(projectAuth, async user => {
  if (!app) initializeApp();

  const { setUserStatus } = useUser();

  if (!user) {
    storageSession.clear();

    try {
      await signOut(getAuth());
      setUserStatus();
    } catch (err) {
      handleRuntimeErrors(err);
    }
    return;
  }

  const token = await user.getIdToken();

  apiService.intercept(token);
  apiMultipartService.intercept(token);

  const { data } = await apiService.get<UserDetailsAdmin>({ endpoint: `users/${user.uid}` });

  if (data) await setUserStatus(data);

  const teamStore = useTeamStore();

  await teamStore.fetchTeams();
  await teamStore.migrateTeamProjects();

  if (teamStore.isSubscriptionLate) {
    const subscription = teamStore.subscriptions.find(subscription => subscription.status === "overdue");

    if (subscription) {
      const res = await apiService.get<{ url: string }>({
        endpoint: `subscriptions/${subscription.id}/payment-link`
      });

      if (res.error || !res.data?.url) return;

      teamStore.setPaymentLink(res.data.url);
    }
  }

  const projectStore = useProjectStore();

  await projectStore.fetch({ simplified: true, page: 1 });
});
